<template>
    <Dialog title="评论回复" width="50%" :visible="ShowSecondaryCommentsDialog" class="SecondaryComments f-cl-c"
        @closeDialog="closeDialog">
        <div class="mb-20 f-c">
            <el-image class="wh-40 borderRa-50 mr-15 alignSelf-start"
                :src="SecondaryParams.firstComment ? SecondaryParams.firstComment.userAvatarUrl : ''"></el-image>
            <div class="f-cl">
                <span>{{ SecondaryParams.firstComment ? SecondaryParams.firstComment.fcommentUsername : '' }}</span>
                <span class="marginTB-10">{{ SecondaryParams.firstComment ? SecondaryParams.firstComment.fcommentConent : ''
                }}</span>
                <span>{{ SecondaryParams.firstComment ? SecondaryParams.firstComment.createDate : '' }}</span>
            </div>
        </div>
        <div class="line"></div>
        <div class="second-box" v-infinite-scroll="load" infinite-scroll-disabled="disabled"
            infinite-scroll-immediate="false">
            <span class="tips">共回复 {{ secondaryCommentTotal }}</span>
            <CommentItem :list="secondaryCommentList" :CommentType="'second'" @uploadSecondaryList="uploadSecondaryList"
                @secondaryBoxComment="secondaryBoxComment" :queryType="queryType">
            </CommentItem>
        </div>
        <p v-if="loading">加载中...</p>
        <p v-if="noMore">没有更多了</p>
    </Dialog>
</template>
<script>
export default {
    name: 'SecondaryComments',
    inject: ['reload'],
    components: {
        CommentItem: () => import("./CommentItem.vue")
    },
    data() {
        return {
            secondaryCommentList: [],//二级评论列表
            secondaryCommentTotal: 0,//二级评论条数
            loading: false,
            noMore: false,
            page: 1,
            size: 10,
            currentPage: 0,
            totalPages: 0,
            isDeleteSecondComment: false,//是否触发删除二级评论
        }
    },
    props: {
        ShowSecondaryCommentsDialog: {//是否显示二级
            type: Boolean,
            default: false
        },
        SecondaryParams: {},//二级评论参数
        queryType: {}

    },
    computed: {
        disabled() {
            return this.loading
        }
    },
    watch: {
        ShowSecondaryCommentsDialog(newName) {
            if (newName) {
                this.$setStorage('SecondaryParams', this.SecondaryParams)
                this.getSecondaryCommentList()
            }

        },
    },
    methods: {
        closeDialog() {
            this.secondaryCommentList = [];
            this.$emit('closeSecondaryCommentsDialog', this.isDeleteSecondComment)
            this.isDeleteSecondComment = false;
        },

        // 用户移除后更新列表
        uploadSecondaryList() {
            this.secondaryCommentList = []
            this.isDeleteSecondComment = true
            this.getSecondaryCommentList()
        },
        // 获取二级评论列表
        getSecondaryCommentList() {
            switch (this.queryType) {
                case 'ProductExchange':
                    this.forumPostSecondaryCommentList();
                    break;
                case 'ShiLiaoZaTan':
                    this.forumChatPostSecondaryCommentList()
                    break;


            }
        },
        // 获取二级评论列表参数
        getSecondaryCommentListParams() {
            let that = this;
            return {
                mcommentUserId: Number(that.$getStorage('userId')),
                postId: that.SecondaryParams.postId,
                token: that.$getStorage('token'),
                page: that.page,
                size: that.size,
                connectFcommentId: that.SecondaryParams.fcommentId
            }
        },
        // 处理二级评论返回数据
        handleSecondaryCommentListData(res) {
            let that = this;
            if (res.code == 200) {
                res.data.records.forEach((item) => {
                    item.showCommonInput = false
                })
                that.secondaryCommentList = that.secondaryCommentList.concat(res.data.records)
                that.secondaryCommentTotal = res.data.total
                that.currentPage = res.data.current;//当前页数
                that.totalPages = res.data.pages//总页数
                that.loading = false
            }
        },
        //产品交流----二级评论模块--帖子二级评论列表接口
        forumPostSecondaryCommentList() {
            let that = this;
            that.$http.forumPostSecondaryCommentList(that.getSecondaryCommentListParams()).then(res => {
                that.handleSecondaryCommentListData(res)
            })
        },
        // 识料杂谈帖子模块---二级评论模块
        forumChatPostSecondaryCommentList() {
            let that = this;
            that.$http.forumChatPostSecondaryCommentList(that.getSecondaryCommentListParams()).then(res => {
                that.handleSecondaryCommentListData(res)
            })
        },
        load() {
            if (this.currentPage < this.totalPages) {
                this.loading = true
                this.page++
                this.getSecondaryCommentList()
            } else if (this.currentPage == this.totalPages) {
                this.noMore = true
                setTimeout(() => {
                    this.noMore = false
                }, 1500)
            }
        },
        secondaryBoxComment() {
            this.uploadSecondaryList()
        }
    }
}
</script>

<style lang="less" scoped>
.SecondaryComments {
    .line {
        border: 2px solid #eee;
    }

    .second-box {
        height: 450px !important;
        overflow-y: auto !important;
        padding: 0 20px;

        .tips {
            margin-left: -20px;
            padding: 10px 0;
            display: block;
        }
    }

    p {
        text-align: center;
    }
}
</style>